/* eslint-disable no-undef */
import React from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './Footer.scss';

const Footer = () => {
	const { t } = useTranslation();

	const cookieFunc = () => {
		console.log('cookieFunc');
		let val = localStorage.getItem('NCM_lang')
			? localStorage.getItem('NCM_lang')
			: 'en';
		if (window.OneTrust) {
			window.OneTrust.changeLanguage(val);
		}
		if (Optanon) {
			Optanon.ToggleInfoDisplay();
		} else if (window.Optanon) {
			window.Optanon.ToggleInfoDisplay();
		} else {
			return;
		}
	};

	const login = () => {
		localStorage.setItem('NCM_login', 'NETIQ');
		let redirectUrl = '';
		let client_id = '';
		let federation = '';
		if (window.location.origin.includes('localhost')) {
			redirectUrl = 'https://qual.ncm.basf.com';
			client_id = '355006f9-603c-4642-a38a-6460e0737b57';
			federation = 'https://federation-qa.basf.com/nidp/oauth/nam/authz';
		} else if (window.location.origin.includes('dev')) {
			redirectUrl = 'https://dev.ncm.basf.com';
			client_id = 'a9526525-7323-41db-8a3f-955090557519';
			federation = 'https://federation.basf.com/nidp/oauth/nam/authz';
		} else if (window.location.origin.includes('qual')) {
			redirectUrl = 'https://qual.ncm.basf.com';
			client_id = '355006f9-603c-4642-a38a-6460e0737b57';
			federation = 'https://federation-qa.basf.com/nidp/oauth/nam/authz';
		} else {
			redirectUrl = 'https://ncm.basf.com';
			client_id = 'a9526525-7323-41db-8a3f-955090557519';
			federation = 'https://federation.basf.com/nidp/oauth/nam/authz';
		}
		const fedPath = `${federation}?client_id=${client_id}&scope=UserProfileService&response_type=code&redirect_uri=${encodeURIComponent(
			redirectUrl
		)}`;
		document.location.href = fedPath;
	};

	const login1 = () => {
		localStorage.setItem('NCM_login', 'EntraID');
		let redirectUrl = '';
		let client_id = '';
		let federation = '';
		if (window.location.origin.includes('localhost')) {
			redirectUrl = 'https://dev.ncm.basf.com';
			client_id = '23b3cea4-502f-45ca-9dd1-070ba45ef0f5';
			federation =
				'https://login.microsoftonline.com/92f2e472-54a6-4aaa-a626-eb07617bc272/oauth2/v2.0/authorize';
		} else if (window.location.origin.includes('dev')) {
			redirectUrl = 'https://dev.ncm.basf.com';
			client_id = '23b3cea4-502f-45ca-9dd1-070ba45ef0f5';
			federation =
				'https://login.microsoftonline.com/92f2e472-54a6-4aaa-a626-eb07617bc272/oauth2/v2.0/authorize';
		} else if (window.location.origin.includes('qual')) {
			redirectUrl = 'https://qual.ncm.basf.com';
			client_id = '9395610d-2e9d-443a-a27e-714ca919def1';
			federation =
				'https://login.microsoftonline.com/3ac0bc5f-a269-4819-8f30-258bed6461cb/oauth2/v2.0/authorize';
		} else {
			// 	redirectUrl = 'https://ncm.basf.com';
			// 	client_id = 'a9526525-7323-41db-8a3f-955090557519';
			// 	federation = 'https://federation.basf.com/nidp/oauth/nam/authz';
		}
		const fedPath = `${federation}?client_id=${client_id}&response_type=code&redirect_uri=${encodeURIComponent(
			redirectUrl
		)}&scope=profile%20openid%20offline_access&response_mode=query&prompt=login`;
		document.location.href = fedPath;
	};

	return (
		<footer className="print_hide">
			<span className="internallogin" onClick={() => login()}></span>
			{/* <span className="internallogin1" onClick={() => login1()}></span> */}
			<ul className="lstFooter">
				<li>
					<a
						target="_blank"
						href="https://www.basf.com/global/en/legal/credits.html"
						rel="noopener noreferrer"
					>
						{t('Footer.IR')}
					</a>
				</li>
				<li>
					<NavLink to="/privacy" activeClassName="current" exact>
						{t('Footer.PP')}
					</NavLink>
				</li>
				<li>
					<NavLink to="/support" activeClassName="current" exact>
						{t('Footer.Support')}
					</NavLink>
				</li>
				<li>
					<a
						target="_blank"
						href="https://www.basf.com/global/en/legal/disclaimer.html"
						rel="noopener noreferrer"
					>
						{t('Footer.Disclaimer')}
					</a>
				</li>
				<li>
					<span onClick={() => cookieFunc()}>{t('Footer.Cookies')}</span>
				</li>
				<li>
					<NavLink to="/faq" activeClassName="current">
						{t('Footer.FAQ')}
					</NavLink>
				</li>
				<li>
					<NavLink to="/detailedguides" activeClassName="current">
						{t('Footer.DetailedGuides')}
					</NavLink>
				</li>
				<li className="trademark">
					{/* <p>Ver: 1.00</p> */}
					<p>Copyright © BASF SE 2024</p>
				</li>
			</ul>
		</footer>
	);
};

export default Footer;
